import React from 'react'
import { Link } from 'gatsby'

import { Col, Container, Row } from 'react-bootstrap'


import Layout from "../components/layout"
import ParallaxSpotlight from '../components/ParallaxSpotlight'
import SpotlightArticle from "../components/SpotlightArticle"
import otherEssSpotlight from "../media/img/who-we-are/who-we-are-spotlight.jpg"
import SEO from "../components/seo"
import BorderLayout from '../components/BorderLayout'
import globalThumb1 from "../media/img/who-we-are/global-1.jpg"
import globalThumb2 from "../media/img/who-we-are/global-2.jpg"
import globalOfficeImage from "../media/img/who-we-are/map.jpg"
import delhiOfficeImage from "../media/img/who-we-are/delhi-office.jpg"
import delhiThumb1 from "../media/img/who-we-are/delhi-1.jpg"
import delhiThumb2 from "../media/img/who-we-are/delhi-2.jpg"
import delhiThumb3 from "../media/img/who-we-are/delhi-3.jpg"
import valuesImage from "../media/img/who-we-are/values-parent.jpg"
import valuethumb1 from "../media/img/who-we-are/Our-values-thumb1.jpg"
import valuethumb2 from "../media/img/who-we-are/Our-values-thumb2.jpg"
import valuethumb3 from "../media/img/who-we-are/Our-values-thumb3.jpg"
import valuethumb4 from "../media/img/who-we-are/Our-values-thumb4.jpg"
import parallaxIcon1 from "../media/img/who-we-are/icons/icn-1.jpg"
import parallaxIcon2 from "../media/img/who-we-are/icons/icn-2.jpg"
import parallaxIcon3 from "../media/img/who-we-are/icons/icn-3.jpg"
import parallaxIcon4 from "../media/img/who-we-are/icons/icn-4.jpg"
import parallaxIcon5 from "../media/img/who-we-are/icons/icn-5.jpg"
import parallaxIcon7 from "../media/img/who-we-are/icons/icn-7.jpg"
import parallaxIcon8 from "../media/img/who-we-are/icons/icn-8.jpg"
import parallaxIcon9 from "../media/img/who-we-are/icons/icn-9.jpg"
import parallaxIcon10 from "../media/img/who-we-are/icons/icn-10.jpg"

import { ParallaxProvider } from 'react-scroll-parallax'
import ParallaxIcon from '../components/ParallaxIcon'
import Wiedenism from '../components/Wiedenism'

export default function WhoWeAre() {
    return (
        <Layout pageInfo={{ pageName: "Who We Are" }}>
            <SEO title="Who We Are" />
            <SpotlightArticle tags={`Who We Are`} title={`Here’s your crash course on W+K. From knowing the different offices in the network and the global management team to everything about the work and people right here in W+K India, this section will take care of all that. Here you’ll find answers to all FAQs. And even some other Qs that aren’t always FA.`} bgImg={otherEssSpotlight} />
            <div className="who-we-are">
                <ParallaxProvider>
                    <ParallaxSpotlight image={globalOfficeImage} title={`W+K GLOBAL`} color="#FDE351" externalInsta="https://www.instagram.com/wiedenkennedy/" externalWeb="https://www.wk.com" />
                 
                    <div className="home__alternate__blocks">
                        <Container>
                            <Row>
                                <ParallaxIcon src={parallaxIcon1} left={-10} top={5} parallax={[300,-300]} />
                                <ParallaxIcon src={parallaxIcon2} left={70} top={-5} parallax={[100,-100]} />
                                <ParallaxIcon src={parallaxIcon3} left={100} top={65} parallax={[200,-200]} />
                                <Col className="image">
                                    <BorderLayout>
                                        <img src={globalThumb1} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>OUR HISTORY</h3>
                                        <p>On April Fool’s day 1982, Dan Wieden and David Kennedy set up a shop to service a small, local client called Nike who made shoes for runners in Portland, Oregon. And the rest of course, as we all know is history.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon4} left={-10} top={55} parallax={[300,-300]} />
                                <ParallaxIcon src={parallaxIcon5} left={80} top={110} parallax={[100,-100]} />
                                <Col className="image">
                                    <BorderLayout >
                                        <img src={globalThumb2} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>OUR PURPOSE</h3>
                                        <p>We are an independent, creatively driven advertising agency that strives to create a culture. Also, where unique people come together to do the best work of their lives and have a damn good time in the process. We exist to create strong and provocative relationships between good companies and their customers.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    
                    <ParallaxSpotlight class="reverse hideAhref" image={valuesImage} title={`OUR VALUES`} color="#31e510" />
                    <div className="home__alternate__blocks">
                        <Container>
                            <Row>
                                <ParallaxIcon src={parallaxIcon7} left={-5} top={40} parallax={[200,-200]} />
                                <ParallaxIcon src={parallaxIcon8} left={95} top={35} parallax={[150,-150]} />
                                <Col className="image text-center">
                                    <BorderLayout>
                                        <img src={valuethumb1} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>WALK IN STUPID</h3>
                                        <p>There is a danger in this business of relying on what’s worked in the past—falling back on old habits and ideas. The minute you think you have this thing figured out is the minute you start making derivative work that the public filters out and ignores. In which case you’ll be heading out the door instead of in it.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon9} left={95} top={35} parallax={[300,-300]} />
                                <Col className="image">
                                    <BorderLayout>
                                        <img src={valuethumb2} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>THE WORK COMES FIRST</h3>
                                        <p>We are saying that things work best when everyone — client and agency alike — is focused on whether or not this is great damn work. Politics aside. Egos aside. Is this hot shit, or not?</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon10} left={45} top={35} parallax={[200,-200]} />
                                <Col className="image text-center">
                                    <BorderLayout>
                                        <img src={valuethumb3} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>FAIL HARDER</h3>
                                        <p>It sounds like a command. But it’s not. It’s a permission slip. In offices full of highly motivated individuals, you don’t need to tell people to try harder. People like that are hard enough on themselves. But you do need to give people permission to fail. Because when failure is not an option, risks get avoided and fear drives decision making—and true creative success, in all its surprisingly wonderful, messy glory, is no longer possible.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon9} left={95} top={35} parallax={[300,-300]} />
                                <Col className="image">
                                    <BorderLayout>
                                        <img src={valuethumb4} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>INDEPENDENCE IS EVERYTHING</h3>
                                        <p>We can take risks and make decisions based on what we believe. It means we can recruit and retain the best talent because we don’t have to answer to a board of directors or shareholders. It lets us be obsessive about the power of great work that makes brands successful, and makes our clients and ourselves proud.</p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Wiedenism />
                    <ParallaxSpotlight image={delhiOfficeImage} title={`W+K INDIA`} color="#10cfe9" externalInsta="https://www.instagram.com/wk_delhi/" externalWeb="https://www.wkdelhi.com/" />
                    <div className="home__alternate__blocks">
                        <Container>
                            <Row>
                                <ParallaxIcon src={parallaxIcon7} left={-5} top={40} parallax={[200,-200]} />
                                <ParallaxIcon src={parallaxIcon8} left={95} top={35} parallax={[150,-150]} />
                                <Col className="image text-center">
                                    <BorderLayout >
                                        <img src={delhiThumb1} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>ABOUT WK INDIA</h3>
                                        <p>W+K India, founded in 2007, is a mix of people from all backgrounds and skill sets. We help brands find their voice, and help them use it to be a real part of people’s lives. We strive to create culture, we want to make a difference. We want to tell a story that needs to be heard, we want to build on ideas that are unheard of, we want to shake things a little, every now and then.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon9} left={95} top={35} parallax={[300,-300]} />
                                <Col className="image">
                                    <BorderLayout >
                                        <img src={delhiThumb2} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>OUR WORK</h3>
                                        <p><Link target="_blank" to="https://www.wkdelhi.com/">Take a look at our work here.</Link></p>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <ParallaxIcon src={parallaxIcon10} left={45} top={35} parallax={[200,-200]} />
                                <Col className="image text-center">
                                    <BorderLayout >
                                        <img src={delhiThumb3} alt="" />
                                    </BorderLayout>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <div className="copy">
                                        <h3>OUR PEOPLE</h3>
                                        <p><Link target="_blank" to="/connect">Get to know our people here.</Link></p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                    <Wiedenism />
                    {/* <div className="home__relative__links">
                        <Container>
                            <Row className="d-flex">
                             
                                <Col>
                                    <BorderLayout color={`#30A2DE`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`The Cheat Sheet`} slug={`/the-cheat-sheet`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#FFB100`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`Learn & Grow`} slug={`/learn-and-grow`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#8163E7`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`Other Essentials`} slug={`/other-essentials`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                                <Col>
                                    <BorderLayout color={`#A8233D`}>
                                        <div className="links__Wrap">
                                            <RelatedArticleSingle title={`What's Happening`} slug={`/whats-happening`} />
                                        </div>
                                    </BorderLayout>
                                </Col>
                            </Row>
                        </Container>
                    </div> */}
                </ParallaxProvider>
            </div>
        </Layout>
    )
}
