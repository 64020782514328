import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import { Parallax } from 'react-scroll-parallax'
import { GrInstagram } from 'react-icons/gr'
import { FiGlobe } from 'react-icons/fi'

export default function ParallaxSpotlight(props) {
    return (
        <div className={`parallax__Spotlight__Wrap ${props.class}`}>
            <Container>
                <Row>
                    <Col>
                        <div className="image" style={{border: `1.5rem solid ${props.color}`}}>
                            <img src={props.image} alt="" />
                        </div>
                        <div class="external--links--wrap">
                            <a target="_blank" rel="noreferrer" href={props.externalInsta}>   
                                <GrInstagram />
                            </a>
                            <a target="_blank" rel="noreferrer" href={props.externalWeb}>   
                                <FiGlobe />
                            </a>
                        </div>
                        <Parallax className="title" y={[-200, 100]} tagOuter="figure">
                            {props.title}
                        </Parallax>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}
ParallaxSpotlight.defaultProps = {
    class:"",
    image: "",
    title: "",
    color: "#F35C65",
    externalWeb: "",
    externalInsta: ""
}
